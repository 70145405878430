import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";

// Define interface to match the project structure
export interface IProject {
  id?: number;
  slug?: string;
  type?: string;
  name?: string;
  investor?: string;
  thumbnailUrl?: string;
  location?: string;
  year?: string;
  description?: string;
  createdDate?: string;
  detailImageUrls?: string[];
  isActive?: boolean;
  isFeature?: boolean;
}

class ProjectsStore {
  projects: IProject[] = [];
  $sizeOfPage: number = 100; // Default page size
  $currentPage: number = 1; // Default current page
  loadingProject: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setProjects(projects: IProject[]) {
    this.projects = projects;
  }

  async getProjects() {
    this.loadingProject = true;
    try {
      const response = await API.get(APIRoutes.GET_PROJECTS);
      this.setProjects(response.data); // Assign data to store
    } catch (error) {
      console.error("There was an error getting the projects!", error);
    } finally {
      this.loadingProject = false;
    }
  }

  get lengthOfProjects() {
    return this.projects.length;
  }

  get sizeOfPages() {
    return this.$sizeOfPage;
  }

  get currentPage() {
    return this.$currentPage;
  }

  setPageSize(size: number) {
    this.$sizeOfPage = size;
  }

  setCurrentPage(page: number) {
    this.$currentPage = page;
  }

  get paginatedProjects() {
    const start = (this.currentPage - 1) * this.$sizeOfPage;
    const end = start + this.$sizeOfPage;
    return this.projects.slice(start, end);
  }
}

export const projectsStore = new ProjectsStore();
