import React, { useEffect } from "react";
import { ImageHolder, Modal, Sidebar } from "../../component";
import { observer } from "mobx-react";
import { authStore, FileItem, staticStore } from "../../stores";
import { API_ENDPOINT } from "../../apis";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { useNavigate } from "react-router-dom";

type Props = {};

const renderFilesRecursively = (
  files: Array<FileItem | string>,
  basePath: string = ""
) => {
  return files.map((fileOrDirectory, index) => {
    if (typeof fileOrDirectory === "string") {
      // It's a file, so render the ImageHolder component
      const imgSrc = `${basePath}/${fileOrDirectory}`;
      return (
        <ImageHolder
          key={imgSrc}
          imgSrcIncludeDomain={`${API_ENDPOINT}/uploads${imgSrc}`}
          onCopy={() => {
            navigator.clipboard.writeText(`/uploads${imgSrc}`);
            const sweetAlert = withReactContent(Swal);
            sweetAlert.fire({
              title: "<p>Copied!</p>",
              timer: 500,
            });
          }}
          onRemove={() => {
            staticStore.setOpenModalComfirm();
            staticStore.setUrlDeleteImage(`/uploads${imgSrc}`);
          }}
        />
      );
    } else if (fileOrDirectory.directory && fileOrDirectory.contents) {
      // It's a directory, recursively render its contents
      const newPath = `${basePath}/${fileOrDirectory.directory}`;
      return (
        <div
          key={newPath}
          className="flex flex-wrap gap-4 border-2 p-2 mt-4 rounded"
        >
          <h3>{fileOrDirectory.directory.toUpperCase()}</h3>
          <div className="flex flex-wrap gap-2">
            {renderFilesRecursively(fileOrDirectory.contents, newPath)}
          </div>
        </div>
      );
    }

    return null;
  });
};

export const Static: React.FC<Props> = observer(() => {
  const navigate = useNavigate();

  const { isLogin } = authStore;

  useEffect(() => {
    if (isLogin) {
      staticStore.getAllFileName();
    } else {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sb-nav-fixed">
      <Sidebar />

      <div className="p-4 sm:ml-40">
        <div className="flex flex-wrap justify-between items-start">
          <div className="flex flex-col flex-wrap gap-2 p-4 rounded border-2 w-full">
            <h3>Upload an image</h3>
            <input
              type="file"
              accept="image/*"
              onChange={(e) => {
                const file = e.target.files?.[0];
                if (file) {
                  staticStore.uploadSigle(file);
                }
              }}
              className="block w-full text-sm text-gray-900 bg-gray-50 border border-gray-300 rounded-lg cursor-pointer focus:outline-none dark:bg-gray-700 dark:text-gray-400 dark:focus:ring-4 dark:focus:ring-gray-600"
            />
          </div>
        </div>
        <div className="relative overflow-x-auto mt-4">
          <div className="flex flex-col flex-wrap gap-2 rounded border-2">
            <h3 className="px-4 pt-4">Uploaded images</h3>
            <div className="flex flex-wrap gap-3 m-4">
              {renderFilesRecursively(staticStore.files)}
            </div>
          </div>
        </div>
      </div>

      <Modal
        message={`Bạn có chắc muốn xoá ảnh có đường dẫn: ${staticStore.urlDeleteImage}`}
        onClose={() => {
          staticStore.setOpenModalComfirm();
          staticStore.setUrlDeleteImage("");
        }}
        onConfirm={() => {
          staticStore.onSubmitDelete();

          setTimeout(() => {
            staticStore.getAllFileName();
          }, 800);
        }}
      />
    </div>
  );
});
