import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";

export interface INews {
  id: number;
  slug: string;
  type: string;
  title: string;
  thumbnailUrl: string;
  brief?: string;
  openningContent?: string;
  htmlContent: string;
  createdDate?: string;
}

class NewsStore {
  news: INews[] = [];
  $sizeOfPage: number = 100; // Default page size
  $currentPage: number = 1; // Default current page

  constructor() {
    makeAutoObservable(this);
  }

  setNews(news: INews[]) {
    this.news = news;
  }

  async getNews() {
    try {
      const response = await API.get(APIRoutes.GET_NEWS);
      this.setNews(response.data);
    } catch (error) {
      console.error("There was an error getting the posts!", error);
    }
  }

  get lengthOfPosts() {
    return this.news.length;
  }

  get sizeOfPages() {
    return this.$sizeOfPage;
  }

  get currentPage() {
    return this.$currentPage;
  }

  setPageSize(size: number) {
    this.$sizeOfPage = size;
  }

  setCurrentPage(page: number) {
    this.$currentPage = page;
  }

  get paginatedPosts() {
    const start = (this.currentPage - 1) * this.$sizeOfPage;
    const end = start + this.$sizeOfPage;
    return this.news.slice(start, end);
  }
}

export const newsStore = new NewsStore();
