import { makeAutoObservable } from "mobx";
import { API, APIRoutes, setApiAccessToken } from "../apis";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export interface ILoginResponse {
  accessToken: string;
  username: string;
}

export interface ILogin {
  username: string;
  password: string;
}

class AuthStore {
  isLogin = false;
  userLogined: ILoginResponse | undefined = undefined;
  loginForm: ILogin = { username: "", password: "" };
  errorLogin: string = "";
  loadingAuth = false;

  constructor() {
    makeAutoObservable(this);
    this.checkLoginStatus();
  }

  setAccessToken = (token: string) => {
    sessionStorage.setItem("access_token", token);
    setApiAccessToken(token);
    this.isLogin = true;
  };

  checkLoginStatus = () => {
    const storedAccessToken = sessionStorage.getItem("access_token");
    if (storedAccessToken) {
      this.isLogin = true;
      setApiAccessToken(storedAccessToken);
    } else {
      this.isLogin = false;
      this.userLogined = undefined;
    }
  };

  handleLogin = async () => {
    this.loadingAuth = true;
    try {
      const { data } = await API.post<ILoginResponse>(
        APIRoutes.LOGIN,
        this.loginForm
      );

      if (data.accessToken) {
        this.setAccessToken(data.accessToken);
        this.errorLogin = "";
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Login successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }
    } catch (error) {
      this.errorLogin = "Invalid username or password.";
    } finally {
      this.loadingAuth = false;
    }
  };

  logout = () => {
    sessionStorage.removeItem("access_token");
    this.isLogin = false;
    this.userLogined = undefined;
  };
}

export const authStore = new AuthStore();
