import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";
import { newsStore } from "./news.store";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";

export interface IFormNews {
  id?: number;
  slug: string;
  type: string;
  title: string;
  thumbnailUrl: string;
  brief?: string;
  openningContent?: string;
  htmlContent: string;
  createdDate?: string;
}

class NewsFormStore {
  formData: IFormNews = {
    slug: "",
    type: "",
    title: "",
    thumbnailUrl: "",
    brief: "",
    openningContent: "",
    htmlContent: "",
  };
  typeOfForm: "add" | "update" | "delete" | undefined = undefined;
  errorForm: string = "";

  constructor() {
    makeAutoObservable(this);
  }

  setField<K extends keyof IFormNews>(name: K, value: IFormNews[K]) {
    this.formData[name] = value;
  }

  resetForm() {
    this.formData = {
      slug: "",
      type: "",
      title: "",
      thumbnailUrl: "",
      brief: "",
      openningContent: "",
      htmlContent: "",
    };
    this.typeOfForm = undefined;
  }

  setError(err: string) {
    this.errorForm = err;
  }

  async getDetailNews(slug: string) {
    try {
      const { data } = await API.get(APIRoutes.GET_NEWS_DETAIL(slug));

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          this.setField(
            key as keyof IFormNews,
            value as IFormNews[keyof IFormNews]
          );
        });
      }

      this.setTypeOfForm("update");
    } catch (error) {
      console.log(error);
    }
  }

  async submitAddForm() {
    try {
      const response = await API.post(APIRoutes.CREATE_NEWS, this.formData);

      if (response.data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>News created successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      newsStore.getNews();
    } catch (error) {
      console.error("There was an error adding the news!", error);
    }
  }

  setTypeOfForm(type: typeof this.typeOfForm) {
    this.typeOfForm = type;
  }

  async updateNews() {
    try {
      const { data } = await API.put(
        APIRoutes.UPDATE_NEWS(this.formData.id || 0),
        this.formData
      );

      if (data) {
        const sweetAlert = withReactContent(Swal);

        sweetAlert.fire({
          title: "<p>News updated successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }
      this.resetForm();

      newsStore.getNews();
    } catch (error) {
      console.error("There was an error updating the news!", error);
    }
  }

  async deleteNews() {
    try {
      const { data } = await API.delete(
        APIRoutes.DELETE_NEWS(this.formData.id || 0)
      );

      const sweetAlert = withReactContent(Swal);
      sweetAlert.fire({
        title: "<p>News deleted successfully!</p>",
        timer: 800,
        icon: "success",
      });

      this.resetForm();
      newsStore.getNews(); // Fetch updated projects list
    } catch (error) {
      console.error("There was an error deleting the project!", error);
    }
  }
}

export const newsFormStore = new NewsFormStore();
