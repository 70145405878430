import React, { useEffect } from "react";
import { NewsForm, Sidebar } from "../../component";
import { observer } from "mobx-react";
import { newsFormStore, newsStore, IFormNews, authStore } from "../../stores";
import { API_ENDPOINT } from "../../apis";
import { formatDateToDDMMYYYY } from "../projects";
import { useNavigate } from "react-router-dom";

type Props = {};

export const News: React.FC<Props> = observer(() => {
  const navigate = useNavigate();

  const { isLogin } = authStore;

  useEffect(() => {
    if (isLogin) {
      newsStore.getNews();
    } else {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sb-nav-fixed relative">
      <Sidebar />

      <div className="p-4 sm:ml-40">
        <div className="flex flex-wrap justify-between items-start">
          <div>
            <button
              type="button"
              onClick={() => {
                newsFormStore.resetForm();
                newsFormStore.setTypeOfForm("add");
              }}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Add New News
            </button>
          </div>
        </div>

        {newsFormStore.typeOfForm && <NewsForm />}

        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-2">
                  No
                </th>
                <th scope="col" className="px-6 py-3 w-2/12">
                  Title
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Thumbnail
                </th>
                <th scope="col" className="px-6 py-3">
                  Created Date
                </th>
                <th scope="col" className="px-6 py-3 w-2/12">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {newsStore.paginatedPosts.map((news, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {news.title}
                  </th>
                  <td className="px-6 py-4">{news.type}</td>
                  <td className="px-6 py-4">
                    <div>
                      <img
                        className="w-48"
                        src={`${API_ENDPOINT}${news.thumbnailUrl}`}
                        alt={news.thumbnailUrl}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4">
                    {formatDateToDDMMYYYY(new Date(news.createdDate || ""))}
                  </td>
                  <td className="px-6 py-4">
                    <span className="flex flex-wrap gap-1.5">
                      <button
                        type="button"
                        onClick={() => {
                          newsFormStore.getDetailNews(news.slug || "");
                        }}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          Object.entries(news).forEach(([key, value]) => {
                            newsFormStore.setField(
                              key as keyof IFormNews,
                              value as IFormNews[keyof IFormNews]
                            );
                          });
                          newsFormStore.setTypeOfForm("delete");
                        }}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        Delete
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
