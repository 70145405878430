import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";

export interface FileItem {
  directory?: string;
  contents?: Array<FileItem | string>; // Recursive type to handle nested directories.
}

class StaticStore {
  isOpenModalComfirm: boolean = false;
  urlDeleteImage: string = "";
  urlUpload: string = "";
  files: Array<FileItem | string> = [];
  loadingStatic = false;

  constructor() {
    makeAutoObservable(this);
  }

  setUrlDeleteImage(url: string) {
    this.urlDeleteImage = url;
  }

  setOpenModalComfirm() {
    this.isOpenModalComfirm = !this.isOpenModalComfirm;
  }

  async uploadSigle(file: any) {
    this.loadingStatic = true;
    try {
      const formData = new FormData();
      formData.append("file", file);

      const response = await API.post(APIRoutes.UPLOAD_SINGLE_IMAGE, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Upload an image successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.getAllFileName();
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingStatic = false;
    }
  }

  async getAllFileName() {
    this.loadingStatic = true;

    try {
      const { data } = await API.get(APIRoutes.GET_IMAGES);
      if (data) {
        this.files = data.files;
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingStatic = false;
    }
  }

  async onSubmitDelete() {
    this.loadingStatic = true;
    try {
      const formData = new URLSearchParams();
      formData.append("filePath", this.urlDeleteImage);

      const response = await API.delete(APIRoutes.DELETE_IMAGES, {
        data: formData,
        headers: {
          "Content-Type": "application/x-www-form-urlencoded",
          "clean-request": "no-clean",
        },
      });

      if (response.data) {
        this.isOpenModalComfirm = false;
        this.urlDeleteImage = "";
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Image deleted successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingStatic = false;
    }
  }
}

export const staticStore = new StaticStore();
