export const APIRoutes = {
  // Auth
  LOGIN: "/auth/signin",

  // Project routes
  GET_PROJECTS: "/project",
  GET_PROJECT_DETAIL: (slug: string) => `/project/${slug}`,
  CREATE_PROJECT: "/project",
  DELETE_PROJECT: (id: string | number) => `/project/${id}`,
  UPDATE_PROJECT: (id: string | number) => `/project/${id}`,

  // News routes
  GET_NEWS: "/news",
  GET_NEWS_DETAIL: (slug: string) => `/news/${slug}`,
  CREATE_NEWS: "/news",
  DELETE_NEWS: (id: string | number) => `/news/${id}`,
  UPDATE_NEWS: (id: string | number) => `/news/${id}`,

  // Static resource
  GET_IMAGES: "/static/list-files",
  DELETE_IMAGES: "/static",
  UPLOAD_SINGLE_IMAGE: "/static/upload",
};
