import React from "react";
import { observer } from "mobx-react";
import { newsFormStore, projectFormStore, staticStore } from "../../stores";
import "react-quill/dist/quill.snow.css";
import { Modal } from "../popup-modal";
import { EditorNews } from "../editor-news";

type Props = {};

export const newsTypes = [
  "Design - Construction Process",
  "Sample Quotation Contract",
  "Construction Knowledge",
];

export const NewsForm: React.FC<Props> = observer(() => {
  const handleChange = (
    e: React.ChangeEvent<
      HTMLInputElement | HTMLSelectElement | HTMLTextAreaElement
    >
  ) => {
    newsFormStore.setField(
      e.target.name as keyof typeof newsFormStore.formData,
      e.target.value
    );
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { slug, title, thumbnailUrl, type, brief } = newsFormStore.formData;

    if (newsFormStore.typeOfForm === "update") {
      newsFormStore.updateNews();
    } else {
      if (
        slug === "" ||
        title === "" ||
        thumbnailUrl === "" ||
        type === "" ||
        brief === ""
      ) {
        return newsFormStore.setError(
          "Please fill full fields, except description!"
        );
      }
      newsFormStore.submitAddForm();
    }
  };

  return (
    <div className="my-3">
      <h1 className="text-center my-4">
        {newsFormStore.typeOfForm?.toUpperCase()} NEWS
      </h1>
      {newsFormStore.typeOfForm === "delete" ? (
        <div className="w-full border-2 p-6 rounded-md flex flex-wrap">
          <h2>
            Are you sure you want to delete the new:{" "}
            {newsFormStore.formData.title}
          </h2>
          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                newsFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={() => {
                newsFormStore.deleteNews();
              }}
              className="w-[48%] text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {newsFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full border-2 p-6 rounded-md flex flex-wrap"
        >
          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="slug"
              id="slug"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={newsFormStore.formData.slug}
              onChange={handleChange}
              disabled={newsFormStore.typeOfForm === "update"}
            />
            <label
              htmlFor="slug"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Slug (Ex: exploring-architectural-themes-for-your-blog-content)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="title"
              id="title"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={newsFormStore.formData.title}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="title"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Title (Ex: Khám phá các chủ đề về kiến trúc cho nội dung Blog)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group mt-6 text-left">
            <select
              name="type"
              id="type"
              className="block w-full px-2.5 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-600 transition duration-150 ease-in-out disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 peer"
              required
              value={newsFormStore.formData.type}
              onChange={handleChange}
            >
              <option value="">Select a news type</option>
              {newsTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <label
              htmlFor="type"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-8 top-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:top-2.5 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              News Type
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group mt-6 text-left">
            <input
              type="text"
              name="thumbnailUrl"
              id="thumbnailUrl"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={newsFormStore.formData.thumbnailUrl}
              onChange={handleChange}
            />
            <label
              htmlFor="thumbnailUrl"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Thumbnail Url (Ex: /uploads/1727185029610.jpg - Copy from "Images"
              tab)
            </label>
          </div>

          <div className="border-2 relative z-0 w-full pr-4 my-8 group text-left">
            <textarea
              name="brief"
              id="brief"
              className="min-h-56 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={newsFormStore.formData.brief}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="brief"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Brief (Ex: Bạn có đam mê về kiến trúc và đang tìm cách tạo nội
              dung blog hấp dẫn cho một trang web kiến trúc sư không?)
            </label>
          </div>

          <div className="border-2 relative z-0 w-full pr-4 my-8 group text-left">
            <textarea
              name="openningContent"
              id="openningContent"
              className="min-h-56 disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={newsFormStore.formData.openningContent}
              onChange={handleChange}
            />
            <label
              htmlFor="openningContent"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Openning Content (Ex: Xin chào DQH ARCHITECTS; khám phá các chủ đề
              kiến ​​trúc ...)
            </label>
          </div>

          <div className="relative z-0 w-full pr-4 mb-5 group text-left">
            <label
              htmlFor="htmlContent"
              className="block text-sm text-gray-400"
            >
              Html Content
            </label>
            <EditorNews />
          </div>

          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                newsFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="w-[48%] text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {newsFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </form>
      )}
    </div>
  );
});
