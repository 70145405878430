import React from "react";
import { observer } from "mobx-react";
import { projectFormStore, staticStore } from "../../stores";
import "react-quill/dist/quill.snow.css";
import { Editor } from "../editor";
import { API_ENDPOINT } from "../../apis";
import { ImageHolder } from "../image-holder";
import withReactContent from "sweetalert2-react-content";
import Swal from "sweetalert2";
import { Modal } from "../popup-modal";
import { Loading } from "../loading";

type Props = {};

export const projectTypes = ["Residential", "Office", "Commercial", "Retail"];

const placeholderText = "Area: 800 sqm\nDesign Team: DQH Architects";

export const ProjectForm: React.FC<Props> = observer(() => {
  const handleChange = (
    e: React.ChangeEvent<HTMLInputElement | HTMLSelectElement>
  ) => {
    const { name, type, value } = e.target;

    if (type === "checkbox" || type === "radio") {
      projectFormStore.setField(
        name as keyof typeof projectFormStore.formData,
        (e.target as HTMLInputElement).checked
      );
    } else {
      projectFormStore.setField(
        name as keyof typeof projectFormStore.formData,
        value
      );
    }
  };

  const handleSubmit = (e: React.FormEvent) => {
    e.preventDefault();

    const { slug, name, location, investor, year } = projectFormStore.formData;

    if (projectFormStore.typeOfForm === "update") {
      projectFormStore.updateProject();
    } else {
      if (
        slug === "" ||
        name === "" ||
        location === "" ||
        investor === "" ||
        year === "" ||
        projectFormStore.files.length === 0
      ) {
        return projectFormStore.setError(
          "Please fill full fields, except description!"
        );
      }
      projectFormStore.submitAddForm();
    }
  };

  const handleFileChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    if (event.target.files) {
      projectFormStore.setFiles(event.target.files);
    }
  };

  return (
    <div className="my-3 relative">
      {projectFormStore.loadingForm && <Loading />}
      <h1 className="text-center my-4">
        {projectFormStore.typeOfForm?.toUpperCase()} PROJECT
      </h1>
      {projectFormStore.typeOfForm === "delete" ? (
        <div className="w-full border-2 p-6 rounded-md flex flex-wrap">
          <h2>
            Are you sure you want to delete the project:{" "}
            {projectFormStore.formData.name}?
          </h2>
          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                projectFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="button"
              onClick={() => {
                projectFormStore.deleteProject();
              }}
              className="w-[48%] text-white bg-red-500 hover:bg-red-600 focus:ring-4 focus:outline-none focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {projectFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </div>
      ) : (
        <form
          onSubmit={handleSubmit}
          className="w-full border-2 p-6 rounded-md flex flex-wrap"
        >
          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="slug"
              id="slug"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={projectFormStore.formData.slug}
              onChange={handleChange}
              disabled={projectFormStore.typeOfForm === "update"}
            />
            <label
              htmlFor="slug"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Slug (Ex: zenta-house)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="name"
              id="name"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={projectFormStore.formData.name}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="name"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Project Name (Ex: Zenta House)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group mt-6 text-left">
            <select
              name="type"
              id="type"
              className="block w-full px-2.5 py-2 text-sm text-gray-900 bg-white border border-gray-300 rounded-md shadow-sm focus:outline-none focus:ring-2 focus:ring-blue-500 focus:border-blue-600 transition duration-150 ease-in-out disabled:bg-gray-100 disabled:text-gray-500 disabled:border-gray-200 peer"
              required
              value={projectFormStore.formData.type}
              onChange={handleChange}
            >
              <option value="">Select a project type</option>
              {projectTypes.map((type, index) => (
                <option key={index} value={type}>
                  {type}
                </option>
              ))}
            </select>
            <label
              htmlFor="type"
              className="absolute text-sm text-gray-500 duration-300 transform -translate-y-8 top-3 peer-focus:text-blue-600 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-2 peer-placeholder-shown:top-2.5 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Project Type
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group mt-6 text-left">
            <input
              type="text"
              name="investor"
              id="investor"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={projectFormStore.formData.investor}
              onChange={handleChange}
            />
            <label
              htmlFor="investor"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Investor (Ex: Mrs. Uyen or Zenta House ...)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="year"
              id="year"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              required
              value={projectFormStore.formData.year}
              onChange={handleChange}
              placeholder=""
            />
            <label
              htmlFor="year"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Year (Ex: 2024)
            </label>
          </div>

          <div className="relative z-0 w-6/12 pr-4 mb-5 group text-left">
            <input
              type="text"
              name="location"
              id="location"
              className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
              placeholder=""
              required
              value={projectFormStore.formData.location}
              onChange={handleChange}
            />
            <label
              htmlFor="location"
              className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
            >
              Location (Ex: Bạc Liêu)
            </label>
          </div>
          <div className="relative z-0 w-full pr-4 mb-5 group text-left">
            <label
              htmlFor="description"
              className="block text-sm text-gray-400"
            >
              Description
              <div className="flex flex-wrap gap-2 inset-0 text-gray-400 pointer-events-none p-3 whitespace-pre-wrap">
                <span>Ex: </span>
                <span>{placeholderText}</span>
              </div>
            </label>
            <Editor />
          </div>

          <div className="relative z-0 w-full pr-4 mt-4 mb-5 group text-left flex flex-wrap items-between gap-5">
            <div>
              <label
                htmlFor="isActive"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                Is Active:
              </label>
              <div>
                <input
                  type="checkbox"
                  id="isActive"
                  name="isActive"
                  checked={projectFormStore.formData.isActive === true}
                  onChange={handleChange}
                />
                <label htmlFor="isActive">Is Active</label>
              </div>
            </div>

            <div>
              <label
                htmlFor="isActive"
                className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
              >
                Is Selected:
              </label>
              <div>
                <input
                  type="checkbox"
                  id="isFeature"
                  name="isFeature"
                  checked={projectFormStore.formData.isFeature === true}
                  onChange={handleChange}
                />
                <label htmlFor="isFeature">Is Feature</label>
              </div>
            </div>
          </div>

          {projectFormStore.typeOfForm === "add" && (
            <div className="relative z-0 w-full pr-4 mb-5 group text-left">
              <label
                htmlFor="description"
                className="block text-sm text-gray-400"
              >
                Select images for this project
              </label>
              <input
                type="file"
                multiple
                onChange={handleFileChange}
                className="border p-2 w-full"
              />
              {projectFormStore.files && (
                <div className="flex flex-wrap gap-1">
                  <h3>Selected Files:</h3>
                  <ul>
                    {Array.from(projectFormStore.files).map((file, index) => (
                      <li key={index}>{file.name}</li>
                    ))}
                  </ul>
                </div>
              )}
            </div>
          )}

          {projectFormStore.typeOfForm === "update" && (
            <>
              <div className="relative z-0 w-6/12 pr-4 my-5 group text-left">
                <input
                  type="text"
                  name="thumbnailUrl"
                  id="thumbnailUrl"
                  className="disabled:bg-slate-50 disabled:text-slate-500 disabled:border-slate-200 disabled:shadow-none block p-2.5 px-0 w-full text-sm text-gray-900 bg-transparent border-0 border-b-2 border-gray-300 appearance-none focus:outline-none focus:ring-0 focus:border-blue-600 peer"
                  placeholder=""
                  required
                  value={projectFormStore.formData.thumbnailUrl}
                  onChange={handleChange}
                />
                <label
                  htmlFor="thumbnailUrl"
                  className="peer-focus:font-medium absolute text-sm text-gray-500 dark:text-gray-400 duration-300 transform -translate-y-8 scale-75 top-3 -z-10 origin-[0] peer-focus:left-0 peer-focus:text-blue-600 peer-focus:dark:text-blue-500 peer-placeholder-shown:scale-100 peer-placeholder-shown:translate-y-0 peer-focus:scale-75 peer-focus:-translate-y-8"
                >
                  Thumbnail Url (Copy a image url below and paste here)
                </label>
              </div>
              <div className="w-full my-5 flex flex-col flex-wrap">
                <h4 className="text-sm text-gray-400">List Images</h4>
                <div className="flex flex-wrap gap-2">
                  {projectFormStore.formData.detailImageUrls?.map((imgSrc) => (
                    <ImageHolder
                      key={imgSrc}
                      imgSrcIncludeDomain={`${API_ENDPOINT}/uploads/projects/${projectFormStore.formData.slug}/${imgSrc}`}
                      onCopy={() => {
                        navigator.clipboard.writeText(
                          `/uploads/projects/${projectFormStore.formData.slug}/${imgSrc}`
                        );

                        const sweetAlert = withReactContent(Swal);
                        sweetAlert.fire({
                          title: "<p>Copied!</p>",
                          timer: 500,
                        });
                      }}
                      onRemove={() => {
                        staticStore.setOpenModalComfirm();
                        staticStore.setUrlDeleteImage(
                          `/uploads/projects/${projectFormStore.formData.slug}/${imgSrc}`
                        );
                      }}
                    />
                  ))}
                </div>
              </div>
            </>
          )}

          <div className="w-full flex flex-wrap justify-between">
            <button
              type="button"
              onClick={() => {
                projectFormStore.resetForm();
              }}
              className="w-[48%] text-white bg-gray-500 hover:bg-gray-600 focus:ring-4 focus:outline-none focus:ring-gray-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              CANCEL
            </button>
            <button
              type="submit"
              className="w-[48%] text-white bg-blue-500 hover:bg-blue-600 focus:ring-4 focus:outline-none focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 text-center"
            >
              {projectFormStore.typeOfForm?.toUpperCase()}
            </button>
          </div>
        </form>
      )}

      <Modal
        message={`Bạn có chắc muốn xoá ảnh có đường dẫn: ${staticStore.urlDeleteImage}`}
        onClose={() => {
          staticStore.setOpenModalComfirm();
          staticStore.setUrlDeleteImage("");
        }}
        onConfirm={() => {
          staticStore.onSubmitDelete();
          projectFormStore.getDetailProject(
            projectFormStore.formData.slug || ""
          );
        }}
      />
    </div>
  );
});
