import React, { useEffect } from "react";
import { ProjectForm, Sidebar } from "../../component";
import { observer } from "mobx-react";
import {
  projectsStore,
  IFormProject,
  projectFormStore,
  authStore,
} from "../../stores";
import { API_ENDPOINT } from "../../apis";
import { useNavigate } from "react-router-dom";
import "font-awesome/css/font-awesome.min.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCheck, faMultiply } from "@fortawesome/free-solid-svg-icons";
import { Loading } from "../../component/loading";

type Props = {};

// Helper functions
export const formatDateToDDMMYYYY = (date: Date) => {
  const day = String(date.getDate()).padStart(2, "0");
  const month = String(date.getMonth() + 1).padStart(2, "0");
  const year = date.getFullYear();
  return `${day}/${month}/${year}`;
};

export const Projects: React.FC<Props> = observer(() => {
  const navigate = useNavigate();

  const { isLogin } = authStore;

  useEffect(() => {
    if (isLogin) {
      projectsStore.getProjects();
    } else {
      navigate("/login");
    }
  }, [isLogin, navigate]);

  return (
    <div className="sb-nav-fixed relative">
      {projectsStore.loadingProject && <Loading />}

      <Sidebar />

      <div className="p-4 sm:ml-40">
        <div className="flex flex-wrap justify-between items-start">
          <div>
            <button
              type="button"
              onClick={() => {
                projectFormStore.resetForm();
                projectFormStore.setTypeOfForm("add");
              }}
              className="focus:outline-none text-white bg-green-700 hover:bg-green-800 focus:ring-4 focus:ring-green-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-green-600 dark:hover:bg-green-700 dark:focus:ring-green-800"
            >
              Add New Project
            </button>
          </div>
        </div>

        {projectFormStore.typeOfForm && <ProjectForm />}

        {/* <PaginationProjects />} */}

        <div className="relative overflow-x-auto mt-4">
          <table className="w-full text-sm text-left rtl:text-right text-gray-500 dark:text-gray-400">
            <thead className="text-xs text-gray-700 uppercase bg-gray-50 dark:bg-gray-700 dark:text-gray-400">
              <tr>
                <th scope="col" className="px-6 py-2">
                  No
                </th>
                <th scope="col" className="px-6 py-3 w-2/12">
                  Name
                </th>
                <th scope="col" className="px-6 py-3">
                  Type
                </th>
                <th scope="col" className="px-6 py-3">
                  Thumbnail
                </th>
                <th scope="col" className="px-6 py-3">
                  Year
                </th>
                <th scope="col" className="px-6 py-3">
                  Location
                </th>
                <th scope="col" className="px-6 py-3">
                  Is Active
                </th>
                <th scope="col" className="px-6 py-3 w-1/12">
                  Is Selected
                </th>
                <th scope="col" className="px-6 py-3 w-2/12">
                  Actions
                </th>
              </tr>
            </thead>
            <tbody>
              {projectsStore.paginatedProjects.map((project, index) => (
                <tr
                  key={index}
                  className="bg-white border-b dark:bg-gray-800 dark:border-gray-700"
                >
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {index + 1}
                  </th>
                  <th
                    scope="row"
                    className="px-6 py-4 font-medium text-gray-900 whitespace-nowrap dark:text-white"
                  >
                    {project.name}
                  </th>
                  <td className="px-6 py-4">{project.type}</td>
                  <td className="px-6 py-4">
                    <div>
                      <img
                        className="w-48"
                        src={`${API_ENDPOINT}${project.thumbnailUrl}`}
                        alt={project.name}
                      />
                    </div>
                  </td>
                  <td className="px-6 py-4">{project.year}</td>
                  <td className="px-6 py-4">{project.location}</td>
                  <td className="px-6 py-4">
                    {project.isActive ? (
                      <span className="text-green-500">
                        <FontAwesomeIcon icon={faCheck} size="2xl" />
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <FontAwesomeIcon icon={faMultiply} size="2xl" />
                      </span>
                    )}
                  </td>

                  <td className="px-6 py-4">
                    {project.isFeature ? (
                      <span className="text-green-500">
                        <FontAwesomeIcon icon={faCheck} size="2xl" />
                      </span>
                    ) : (
                      <span className="text-red-500">
                        <FontAwesomeIcon icon={faMultiply} size="2xl" />
                      </span>
                    )}
                  </td>
                  <td className="px-6 py-4">
                    <span className="flex flex-wrap gap-1.5">
                      <button
                        type="button"
                        onClick={() => {
                          projectFormStore.getDetailProject(project.slug || "");
                        }}
                        className="text-white bg-blue-700 hover:bg-blue-800 focus:ring-4 focus:ring-blue-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-blue-600 dark:hover:bg-blue-700 focus:outline-none dark:focus:ring-blue-800"
                      >
                        Edit
                      </button>
                      <button
                        type="button"
                        onClick={() => {
                          Object.entries(project).forEach(([key, value]) => {
                            projectFormStore.setField(
                              key as keyof IFormProject,
                              value as IFormProject[keyof IFormProject]
                            );
                          });
                          projectFormStore.setTypeOfForm("delete");
                        }}
                        className="focus:outline-none text-white bg-red-700 hover:bg-red-800 focus:ring-4 focus:ring-red-300 font-medium rounded-lg text-sm px-5 py-2.5 dark:bg-red-600 dark:hover:bg-red-700 dark:focus:ring-red-900"
                      >
                        Delete
                      </button>
                    </span>
                  </td>
                </tr>
              ))}
            </tbody>
          </table>
        </div>
      </div>
    </div>
  );
});
