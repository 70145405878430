import { makeAutoObservable } from "mobx";
import { API, APIRoutes } from "../apis";
import Swal from "sweetalert2";
import withReactContent from "sweetalert2-react-content";
import { projectsStore } from "./projects.store";

export interface IFormProject {
  id?: number;
  name: string;
  investor: string;
  slug: string;
  type: string;
  location: string;
  year: string;
  description: string;
  thumbnailUrl?: string;
  detailImageUrls?: string[];
  isActive?: boolean;
  isFeature?: boolean;
}

class ProjectFormStore {
  formData: IFormProject = {
    name: "",
    investor: "",
    slug: "",
    type: "",
    location: "",
    year: "",
    description: "",
    thumbnailUrl: "",
    detailImageUrls: [],
    isActive: false,
    isFeature: false,
  };
  files: File[] = [];
  typeOfForm: "add" | "update" | "delete" | undefined = undefined;
  errorForm: string = "";
  loadingForm: boolean = false;

  constructor() {
    makeAutoObservable(this);
  }

  setField<K extends keyof IFormProject>(name: K, value: IFormProject[K]) {
    this.formData[name] = value;
  }

  setFiles(files: FileList | null) {
    if (files) {
      this.files = Array.from(files);
    }
  }

  setError(err: string) {
    this.errorForm = err;
  }

  setTypeOfForm(type: typeof this.typeOfForm) {
    this.typeOfForm = type;
  }

  resetForm() {
    this.formData = {
      name: "",
      investor: "",
      slug: "",
      type: "",
      location: "",
      year: "",
      description: "",
      thumbnailUrl: "",
      detailImageUrls: [],
      isActive: false,
      isFeature: false,
    };
    this.files = [];
    this.typeOfForm = undefined;
  }

  async getDetailProject(slug: string) {
    this.loadingForm = true;

    try {
      const { data } = await API.get(APIRoutes.GET_PROJECT_DETAIL(slug));

      if (data) {
        Object.entries(data).forEach(([key, value]) => {
          this.setField(
            key as keyof IFormProject,
            value as IFormProject[keyof IFormProject]
          );
        });
      }

      console.log(this.formData);

      projectFormStore.setTypeOfForm("update");
    } catch (error) {
      console.log(error);
    } finally {
      this.loadingForm = false;
    }
  }

  async submitAddForm() {
    this.loadingForm = true;
    try {
      const formData = new FormData();
      Object.entries(this.formData).forEach(([key, value]) => {
        formData.append(key, value as string);
      });
      this.files.forEach((file) => {
        formData.append("files", file);
      });

      const response = await API.post(APIRoutes.CREATE_PROJECT, formData, {
        headers: { "Content-Type": "multipart/form-data" },
      });

      if (response.data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Project created successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      projectsStore.getProjects();
    } catch (error) {
      console.error("There was an error adding the project!", error);
    } finally {
      this.loadingForm = false;
    }
  }

  async updateProject() {
    this.loadingForm = true;
    try {
      const { data } = await API.put(
        APIRoutes.UPDATE_PROJECT(this.formData.id || 0),
        this.formData
      );
      if (data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Project updated successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      projectsStore.getProjects();
    } catch (error) {
      console.error("There was an error updating the project!", error);
    } finally {
      this.loadingForm = false;
    }
  }

  async deleteProject() {
    this.loadingForm = true;
    try {
      const { data } = await API.delete(
        APIRoutes.DELETE_PROJECT(this.formData.id || 0)
      );
      if (data) {
        const sweetAlert = withReactContent(Swal);
        sweetAlert.fire({
          title: "<p>Project deleted successfully!</p>",
          timer: 800,
          icon: "success",
        });
      }

      this.resetForm();
      projectsStore.getProjects(); // Fetch updated projects list
    } catch (error) {
      console.error("There was an error deleting the project!", error);
    } finally {
      this.loadingForm = false;
    }
  }
}

export const projectFormStore = new ProjectFormStore();
